.event{
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  grid-column: span 3;
  border-radius: $outer-box-border-radius;
  border: 1px solid $border-color;
  background-color: $primary-bg-color;
  cursor: pointer;
  transition: background-color .35s ease;

  @media (hover: hover) {
    &:hover{
      background-color: $secondary-bg-color;
    }
  }

  @media screen and (max-width: 1280px) {
    grid-column: span 4;
  }
  @media screen and (max-width: 1024px) {
    grid-column: span 6;
  }
  @media screen and (max-width: 500px) {
    padding: 10px;
    border-radius: $inner-box-border-radius;
  }
}

.event__banner{
  position: relative;
  padding-top: 100%;
  border-radius: $inner-box-border-radius;
  overflow: hidden;
}

.event__info{
  padding-top: 20px;

  @media screen and (max-width: 500px) {
    padding-top: 10px
  }
}

.event__h3{
  margin-top: 5px;
  padding-right: 15px;
}

.event__status{
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 25px;
  right: 20px;
  border-radius: 10px;

  @media screen and (max-width: 500px) {
    width: 10px;
    height: 10px;
    bottom: 14px;
    right: 10px;
  }

  &.ongoing{
    background: $green;
  }
  &.unavailable{
    background: $dark-red;
  }
}

.new-event{
  @extend .event;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  @media screen and (max-width: 500px) {
    min-height: 50px;

    & > svg{
      width: 45px;
      height: 45px;
    }
  }
}

.new-event__h2{
  margin-top: 40px;

  @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
  }
}

.event__cancelled{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: $dark-red;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  place-items: center;
  padding: 5px 16px;
  z-index: 9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.event__going{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: $green;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  place-items: center;
  padding: 5px 16px;
  z-index: 9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.event-details__cancelled{
  background-color: $dark-red;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.event-details__going{
  background-color: $green;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.message-container{
  position: fixed;
  z-index: 999999;
  right: 30px;
  top: 30px;

  @media screen and (max-width: 500px) {
    left: 20px;
    right: 20px;
  }
}

.message{
  padding: 10px;
  padding-bottom: 12px;
  border-radius: 10px;
  color: $white;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid transparent;
  background-color: $harmonies;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  animation: fadeAfterBurndown 3s linear both;
  min-width: 300px;

  @media screen and (max-width: 500px) {
    min-width: auto;
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  &::after{
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    animation: burndown 3s linear both;
    left: 0;
    bottom: 0;
  }

  &.success::after{
    background-color: #3BC279;
  }
  &.error::after{
    background-color: #E9594C;
  }
}

.message__data{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message__text{
  margin-left: 10px;
}

.message__remove{
  cursor: pointer;
  display: flex;
  margin-left: 10px;
}

@keyframes burndown {
  0% { width: 100%; }
  95% { width: 0%; }
  100% { width: 0%; }
}
@keyframes fadeAfterBurndown {
  0% { opacity: 1; }
  95% { opacity: 1; }
  100% { opacity: 0; }
}
.canvas__form{
  position: absolute;
  max-width: 500px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  padding: 20px;
  border-radius: $outer-box-border-radius;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  transition: opacity .35s ease;
  pointer-events: none;
  opacity: 0;
  z-index: 99999;

  @media screen and (max-width: 800px) {
    bottom: 40px;
  }
  @media screen and (max-width: 500px) {
    // border-radius: $outer-box-mob-border-radius;
    left: 20px;
    right: 20px;
    bottom: 20px;
    width: auto;
    transform: none;
  }
  @media screen and (max-height: 550px) {
    padding: 10px;
  }

  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

// .canvas__step{
//   // padding-bottom: 20px;
// }

.canvas__personal {
  position: relative;
  max-height: 250px;
}

.canvas__step-back {
  position: absolute;
  right: 0;
  top: -5px;
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  z-index: 9;
}

.button.no-margin{
  margin-top: 0;
}

.canvas__selected-table{
  color: $primary-text-color;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.canvas__selected-table__badge{
  color: $gray;
  font-size: 12px;
}

.canvas__reservation-limited{
  margin-top: 20px;
}

.canvas__limited-text{
  text-align: center;
}

.canvas__reservation-detail__label{
  margin-bottom: 2px;
}

.canvas__form__actions{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 20px;

  @media screen and (max-width: 500px) {
    column-gap: 10px;

    & > .button{
      text-align: center;
    }
  }
}

.PhoneInput{
  position: relative;
}

.PhoneInputCountry{
  position: absolute;
  width: 75px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  left: 1px;
}

.PhoneInputInput{
  padding-left: 75px;
}

.PhoneInputCountrySelectArrow{
  color: $white;
}

.PhoneInputCountryIcon{
  box-shadow: 0 0 0 .5px #fff, inset 0 0 0 .5px #fff;
  margin: 0 3px;
}
.all-users__main{
  padding: 30px 0;

  @media screen and (max-width: 500px) {
    padding: 20px 0;
  }
}

.all-users__role{
  padding-bottom: 40px;

  @media screen and (max-width: 500px) {
    padding-bottom: 20px;
  }
}

.all-users__box{
  border-radius: $inner-box-border-radius;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: border .35s ease;

  @media screen and (max-width: 500px) {
    border-radius: $inner-box-mob-border-radius;
  }

  &:not(:first-of-type){
    margin-top: 15px;

    @media screen and (max-width: 500px) {
      margin-top: 10px;
    }
  }

  & .eyebrow{
    font-size: 11px;

    @media screen and (max-width: 500px) {
      margin-top: 4px;
    }
  }

  & .remove {
    font-size: 14px;
    color: $dark-red;
    cursor: pointer;
  }
}

// base colors
$marie:             #1C1B19;
$bokara:            #201F1E;
$harmonies:         #363431;
$night-rider:       #323232;
$gray:              #AAAAAA;
$light-gray:        #C2C2C2;
$white:             #FFFFFF;
$green:             #1A700C;
$red:               #A11414;
$dark-red:          #A21414;

// use cases
$primary-bg-color:      $marie;
$secondary-bg-color:    $bokara;
$primary-text-color:    $white;
$secondary-text-color:  $gray;
$eyebrow-text-color:    #C7C7C7;
$button-bg-color:       $white;
$button-text-color:     $marie;
$border-color:          $harmonies;
$icon-box:              $night-rider;
.staff-details__main{
  padding: 30px 0;

  @media screen and (max-width: 500px) {
    padding: 20px 0;
  }
}

.staff-details__role{
  padding-bottom: 40px;

  @media screen and (max-width: 500px) {
    padding-bottom: 20px;
  }
}

.staff__checkbox{
  border-radius: $inner-box-border-radius;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: border .35s ease;

  @media screen and (max-width: 500px) {
    border-radius: $inner-box-mob-border-radius;
  }

  &:not(:first-of-type){
    margin-top: 15px;

    @media screen and (max-width: 500px) {
      margin-top: 10px;
    }
  }

  & .eyebrow{
    font-size: 11px;

    @media screen and (max-width: 500px) {
      margin-top: 4px;
    }
  }

  &[data-checked] {
    border: 1px solid $white;

    & > .checkbox__box{
      border: 1px solid $white;

      &::after{
        opacity: 1;
      }
    }
  }
}

.checkbox__box{
  width: 12px;
  height: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  margin-right: 15px;
  position: relative;
  transition: border .35s ease;

  &::after{
    content: '';
    position: absolute;
    width: 2px;
    height: 7px;
    top: 45%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    opacity: 0;
    transition: opacity .35s ease;
  }
}
.event-badge{
  padding: 20px;
  background: rgba(28, 27, 25, 0.3);
  border: 1px solid $harmonies;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: max-content;
  min-width: 350px;

  @media screen and (max-width: 500px) {
    min-width: auto;
    max-width: unset;
  }
}

.event-badge__img{
  width: 50px;
  height: 50px;
  border-radius: 10px;
  position: relative;
  margin-right: 15px;
  overflow: hidden;
}

.event-badge__title{
  font-size: 18px;
  line-height: 21px;
  color: $primary-text-color;
  margin-bottom: 5px;
}
.popup{
  position: fixed;
  width: 400px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 30px;
  z-index: 9999;
  box-sizing: border-box;
  background: rgba(67, 67, 67, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  transform: translateX(100%);
  transition: transform .35s ease;
  overflow-y: auto;

  @media screen and (max-width: 500px) {
    width: 100%;
    border-left: none;
    padding: 30px 20px;
  }

  &.opened{
    transform: translateX(0);
  }
}

.popup__content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.popup__actions{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.popup__title{
  padding: 20px 0;
}

.close-popup{
  cursor: pointer;

  & > img{
    transform: rotate(180deg);
  }
}

.edit{
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: $light-gray;
  cursor: pointer;

  & > img,
  & > svg{
    margin-left: 10px;
  }
}

.popup__button{
  margin-top: 40px;
}
.scan__wrapper{
  position: absolute;
  max-width: 500px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 9999;
  pointer-events: all;
  transition: opacity .35s ease;
  opacity: 1;

  @media screen and (max-width: 800px) {
    bottom: 40px;
    border-radius: 10px;
    padding: 10px;
  }
  @media screen and (max-width: 500px) {
    // border-radius: $outer-box-mob-border-radius;
    left: 20px;
    right: 20px;
    bottom: 20px;
    width: auto;
    transform: none;
  }
  @media screen and (max-height: 550px) {
    padding: 10px;
  }

  &.hidden{
    opacity: 0;
    pointer-events: none;
  }
}

.scanner__wrapper{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 99999;
  padding: 60px;
  transition: opacity .35s ease;
  pointer-events: none;
  opacity: 0;

  @media screen and (max-width: 800px) {
    padding: 30px 20px;
  }

  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.scanner__content{
  display: flex;
  flex-direction: column;
}

.scanner__title{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $primary-text-color;
  margin-top: 100px;
  margin-bottom: 80px;
}

.scanner__numbers{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 10px;
  max-width: 310px;
  align-self: center;
}

.scanner__input{
  width: 70px;
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  text-align: center;
  font-family: $primary-font;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: $primary-text-color;
}
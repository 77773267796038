.club-details__box {
  grid-column: span 6;
  @media screen and (max-width: 500px) {
    grid-column: span 12;
  }
}

.club-details__banner-holder {
  margin: 50px;
}

.club-details__banner {
  position: relative;
  padding-top: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.input-wrapper{
  display: flex;
  flex-direction: column;
  position: relative;

  &--row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.input__label{
  padding: 10px 0;
}

input,
select{
  height: 45px;
  width: -webkit-fill-available;
  padding-left: 15px;
  padding-right: 15px;
  font-family: $primary-font;
  color: $primary-text-color;
  font-weight: 400;
  font-size: 16px;
  line-height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: $inner-box-border-radius;
  background-color: transparent;
  outline: none;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    border-radius: $inner-box-mob-border-radius;
  }

  &::placeholder{
    font-weight: 400;
    color: $secondary-text-color;
  }
}

select{
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 0;

  & > .arrow{
    position: absolute;
    right: 0;
  }

  & > option:invalid,
  & > option:disabled{
    color: $gray;
  }
}

.select-wrapper{
  position: relative;
  margin-bottom: 10px;
}

.select-arrow{
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  pointer-events: none;
}

.select-user{
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border: 1px solid $border-color;
  background-color: #3E3D3C;
  padding: 15px;
  border-radius: $inner-box-border-radius;
  z-index: 9;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background-color .35s ease;

  @media (hover: hover) {
    &:hover{
      background-color: #373635;
    }
  }
}

.select-user__icon{
  margin-right: 15px;
}

.select-user__name{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $primary-text-color;
  margin-bottom: 2px;
}

.select-user__email{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $secondary-text-color;
}

.selected-user{
  padding: 10px 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);;
  background-color: $harmonies;
  border-radius: $inner-box-border-radius;
  cursor: pointer;
}

/* remove input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* remove input type number */
input[type=number] {
  -moz-appearance: textfield;
}
.club{
  padding: 20px;
  box-sizing: border-box;
  grid-column: span 4;
  border-radius: $outer-box-border-radius;
  border: 1px solid $border-color;
  background-color: $primary-bg-color;
  cursor: pointer;
  transition: background-color .35s ease;
  position: relative;

  &:hover{
    background-color: $secondary-bg-color;
  }

  @media screen and (max-width: 1280px) {
    grid-column: span 6;
  }
  @media screen and (max-width: 500px) {
    grid-column: span 12;
  }
}

.club__banner{
  position: relative;
  padding-top: 75%;
  border-radius: $inner-box-border-radius;
  overflow: hidden;
}

.club__info{
  padding-top: 20px;
}

.club__h2{
  margin-bottom: 5px;
}

.club__suspended{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: $dark-red;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  place-items: center;
  padding: 5px 16px;
  z-index: 9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.club__edit{
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $light-gray;
  cursor: pointer;
  margin-top: 10px;

  & > img,
  & > svg{
    margin-right: 10px;
  }
}
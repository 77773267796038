.canvas__wrapper{
  position: fixed;
  z-index: 99999;
  background-color: $primary-bg-color;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(100%);
  animation: slideIn .35s ease forwards;

  & > canvas{
    width: 100%;
    height: 100%;
  }
}

.canvas__header{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding-top: 60px;
  pointer-events: none;

  @media screen and (max-width: 500px) {
    padding-top: 30px;
  }
}

.canvas__nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  
  & > .back-link{
    margin-bottom: 0;
    pointer-events: all;

    & > .label--gray {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
}

.floor__nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: all;

  & > p{
    cursor: pointer;
  }

  & > p.active {
    color: $primary-text-color;
  }

  & > p:not(:first-of-type) {
    margin-left: 40px;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@import '../setup/style.scss';
@import '../components/sidebar';
@import '../components/buttons';
@import '../components/inputs';
@import '../components/user';
@import '../components/content-title';
@import '../components/club';
@import '../components/event';
@import '../components/event-details';
@import '../components/popup';
@import '../components/edit';
@import '../components/section-title';
@import '../components/staff';
@import '../components/staff-details';
@import '../components/invite';
@import '../components/loader';
@import '../components/messages';
@import '../components/canvas';
@import '../components/event-badge';
@import '../components/form';
@import '../components/scan';
@import '../components/club-details';
@import '../components/all-users';

.dashboard{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: auto;
    overflow: unset;
  }
}

.col-4{
  grid-column: span 4;

  @media screen and (max-width: 1280px) {
    grid-column: span 6;
  }
  @media screen and (max-width: 500px) {
    grid-column: span 12;
  }
}

*[data-invalid=true]{
  border-color: $dark-red;
}

.blocked-numbers-grid{
  margin-top: 20px;
}
.staff{
  grid-column: span 4;
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: $outer-box-border-radius;
  background-color: $primary-bg-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background-color .35s ease;

  @media (hover: hover) {
    &:hover{
      background-color: $secondary-bg-color;
    }
  }

  @media screen and (max-width: 1280px) {
    grid-column: span 6;
  }
  @media screen and (max-width: 1024px) {
    grid-column: span 12;
  }
}